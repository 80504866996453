/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@font-face {
  font-family: 'FavoritPro-Book';
  font-weight: 350;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('assets/fonts/FavoritPro-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'FavoritPro-Medium';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('assets/fonts/FavoritPro-Medium.woff2') format('woff2');
}
